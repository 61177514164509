import { DefaultParams } from 'wouter';
import useUserSession from './useUserSession';
import { QueryHookOptions } from '@apollo/client';

const useParticipantLoggedIn = (params: DefaultParams | null, options?: QueryHookOptions) => {
  const { data: sessionData, loading } = useUserSession(options);
  const participants = sessionData?.session.participants;

  const participant = participants?.find(participant => {
    return participant.page?.slug === params?.id;
  });

  const participantLoggedIn = participants?.find(participant => {
    return participant.page?.slug === params?.id && participant.slug === params?.participantSlug;
  });

  return { logged: !!participantLoggedIn, participant: participantLoggedIn, p2pParticipant: participant, loading };
};

export default useParticipantLoggedIn;
