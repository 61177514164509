import React, { FC, useState, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, useLocation } from 'wouter';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import * as TransactionQuery from 'graphql/transaction.graphql';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { TransactionsAndResponsesSpreadsheet } from 'api/data/transaction/types';
import { isKYCd, sortSpaceByName } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFirstOrganization from 'hooks/useFirstOrganization';
import Avatar from 'components/Avatar';
import Badge from 'components/Badge';
import ICONS from 'components/Icons';
import ProtectedComponent from 'components/ProtectedComponent';
import IconButton from 'components/Button/IconButton';
import ReferButton from 'components/Refer/ImageButton';
import Modal from 'components/Modal';
import omellaLogo from 'assets/logo_omella.svg';
import './style.scss';

const SideBar: FC = () => {
  const [location, setLocation] = useLocation();
  const { currentOrg, loading } = useCurrentOrganization({ fetchPolicy: 'cache-only' });
  const { firstOrganizationData, loading: loadingFirstOrg } = useFirstOrganization();
  const [showModalError, setShowModalError] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [transactionAndResponsesSpreadsheet, { loading: spreadsheetLoading }] =
    useMutation<TransactionsAndResponsesSpreadsheet>(TransactionQuery.TransactionsAndResponsesSpreadsheet);

  const organization = currentOrg || firstOrganizationData;

  const orgSpaces = useMemo(() => {
    return sortSpaceByName(organization?.spaces || []);
  }, [organization?.spaces]);

  const spaceSlug = currentSpaceSlug() || firstOrganizationData?.spaces[0]?.slug || '';

  const [expandedReportsMenu, setExpandedReportsMenu] = useState(location === `/${spaceSlug}/reports`);

  const openInGoogleSheets = async () => {
    try {
      const { data } = await transactionAndResponsesSpreadsheet();
      if (data?.transactionsAndResponsesSpreadsheet && data?.transactionsAndResponsesSpreadsheet.spreadsheetUrl) {
        window.open(data.transactionsAndResponsesSpreadsheet.spreadsheetUrl, '_blank')?.focus();
      }
    } catch {
      setShowModalError(true);
    }
  };

  if (loading || loadingFirstOrg) return null;

  return (
    <>
      {openMenu && <div className="side-menu-overlay" role="presentation" onClick={() => setOpenMenu(false)}></div>}
      <IconButton icon="side_bar" className="side-menu-button" onClick={() => setOpenMenu(true)} />
      <div
        className={clsx('side-bar', {
          'banner-space': !!organization && !isKYCd(organization?.status),
          'show-menu': openMenu,
        })}
        data-testid="side-bar">
        <Modal
          handleOnCancel={() => setShowModalError(false)}
          visible={showModalError}
          className="error-modal-response">
          <h4>Oops!</h4>
          <p>Something went wrong.</p>
          <p>Please try reloading this page, or coming back to it in a few minutes.</p>
        </Modal>
        <div className="side-menu-icons-container">
          <IconButton icon="side_bar_close" className="side-menu-button" onClick={() => setOpenMenu(false)} />
          <img src={omellaLogo} alt="Omella logo" />
        </div>
        <div className="side-bar-content">
          <div className="links-container">
            {organization ? (
              <>
                {orgSpaces.length === 1 && (
                  <Link
                    href={`/${spaceSlug}/pages`}
                    className={clsx('link-pages', {
                      'page-selected': location === `/${spaceSlug}/pages`,
                    })}>
                    {ICONS['pages']}
                    Pages
                  </Link>
                )}
                <Link
                  href={`/${spaceSlug}/requests`}
                  className={clsx({ 'page-selected': location === `/${spaceSlug}/requests` })}>
                  {ICONS['resend']}
                  Requests
                </Link>
                {currentOrg && (
                  <>
                    <IconButton
                      icon="reports"
                      className="side-bar-button"
                      onClick={() => setExpandedReportsMenu(!expandedReportsMenu)}>
                      <div className="reports-menu">
                        Reports <Badge label="Beta" />
                        {ICONS[expandedReportsMenu ? 'arrow_up' : 'arrow_right']}
                      </div>
                    </IconButton>
                    <CSSTransition
                      timeout={200}
                      in={expandedReportsMenu}
                      classNames="animation"
                      unmountOnExit
                      exit={false}
                      key="reports-menu-transition">
                      <div className="reports-sub-menu-container">
                        <Link
                          href={`/${spaceSlug}/reports`}
                          className={clsx('link-pages', {
                            'page-selected': location === `/${spaceSlug}/reports`,
                          })}>
                          Submissions
                        </Link>
                        <ProtectedComponent
                          action="ORGANIZATION_GOOGLE_SHEET_REPORTS"
                          currentUserOrganizationRole={currentOrg.currentUserRole}>
                          <button
                            className="button-link icon-button google-sheets-button"
                            onClick={() => void openInGoogleSheets()}
                            disabled={spreadsheetLoading}>
                            {spreadsheetLoading ? 'Opening...' : <>Google Sheet {ICONS['expand_link']}</>}
                          </button>
                        </ProtectedComponent>
                      </div>
                    </CSSTransition>
                  </>
                )}
                <div className="spaces-wrapper">
                  <span className="space-title">
                    Spaces
                    <ProtectedComponent action="CREATE_SPACE" currentUserOrganizationRole={currentOrg?.currentUserRole}>
                      <IconButton
                        className="space-new"
                        icon="plus_new"
                        onClick={() => setLocation(`/${spaceSlug}/new-space`)}
                      />
                    </ProtectedComponent>
                  </span>
                  {orgSpaces.map(space => (
                    <Link
                      className={clsx('space-link', {
                        'space-selected':
                          location.startsWith(`/${space.slug}/pages`) ||
                          location.startsWith(`/${space.slug}/member`) ||
                          location.startsWith(`/${space.slug}/space-settings`),
                      })}
                      key={space.id}
                      href={`/${space.slug}/pages`}>
                      <Avatar text={space.name} size="small" className="align-self-baseline" />
                      <span>{space.name}</span>
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <IconButton icon="profile" className="side-bar-button page-selected">
                Account
              </IconButton>
            )}
          </div>
          <ReferButton />
        </div>
      </div>
    </>
  );
};

export default SideBar;
