import React, { FC } from 'react';
import { RouteComponentProps } from 'wouter';
import { useMutation } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import * as AuthQuery from 'graphql/auth.graphql';
import { extractQueryStringFromParams } from 'utils';
import PageWithLogoLine from 'components/PageWithLogoLine';
import SignupParticipant, { Participant } from '.';
import { CreateParticipantQuery } from 'api/data/user/types';

type PageParams = {
  id: string;
};

const NewParticipant: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [createParticipantMutation, { loading: loadingCreateParticipant, data }] = useMutation<CreateParticipantQuery>(
    PageQuery.CreateParticipant,
    {
      refetchQueries: [AuthQuery.Session],
    },
  );

  const createParticipant = async (participant: Participant) => {
    await createParticipantMutation({
      variables: {
        email: participant.email,
        pageSlug: params.id,
      },
    });
  };

  if (data?.createParticipant)
    return (
      <PageWithLogoLine>
        <div>
          <p className="row justify-center title">Please check your email to login</p>
          <p className="row justify-center paragraph-x-small">
            Omella sent an email to confirm the account creation. <br /> <br />
          </p>
          <p className="row justify-center paragraph-x-small">You can close this tab.</p>
        </div>
      </PageWithLogoLine>
    );

  return (
    <SignupParticipant
      handleSubmit={createParticipant}
      showFields={false}
      loading={loadingCreateParticipant}
      email={extractQueryStringFromParams('email')}
    />
  );
};

export default NewParticipant;
