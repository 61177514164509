import { Dispatch, SetStateAction, RefObject } from 'react';
import { ApolloError } from '@apollo/client/errors';
import { Goal } from 'components/GoalBar/types';
import { Organization, Space, UserParams } from 'api/data/user/types';
import { ResponseActivityType } from 'api/data/response/types';
import { BlockState, BlockStateRaw } from 'api/data/pages/blocks/types';

export const enum ProcessingFeePayer {
  payer = 'PAYER',
  organization = 'ORGANIZATION',
}

export interface Participant {
  id: string;
  slug: string;
  picture: string | null;
  pictureUrl: string | null;
  parentEmail: string | null;
  secondParentEmail: string | null;
  inviteClaimedAt: Date | null;
  message?: string;
  user: UserParams;
  goal: Goal;
  page?: PageData;
  fullName?: string;
  computedEffortStage: 'INVITED' | 'PARTIAL_EFFORT' | 'REGISTERED' | 'TOP_PERFORMER';
  computedInvitedOrDonatedCount: number;
  computedRaisedAmountCents: number;
  computedEffortPoints: number;
  deletedAt?: string;
  valid?: boolean;
  __typename?: string;
}

export interface ParticipantsGoals extends Goal {
  participantMembershipId: string;
}

export type UseEditorReturn = [
  PageDataDraftBlock,
  Dispatch<SetStateAction<PageDataDraftBlock>>,
  boolean,
  ApolloError | undefined,
];

export type PageData = {
  id: string;
  title: string;
  slug: string;
  isTemplate: boolean;
  organizationId: string;
  coverPicture?: string;
  afterSubmissionUrl?: string | null;
  logo?: string;
  blocks: BlockStateRaw[];
  organization?: Organization;
  logoUrl?: string;
  coverPictureUrl?: string;
  status?: string;
  tipsEnabled: boolean;
  processingFeePayer: ProcessingFeePayer;
  requestId?: string;
  participant: Participant | null;
  goal?: Goal | null;
  fullName?: string;
  email?: string;
  sourcePageId?: string;
  customBodyReceiptContent: string;
  customFooterReceiptContent?: string;
  showCustomReceiptContent?: boolean;
  responseActivityTypes?: ResponseActivityType[];
  startDate: Date | null;
  endDate: Date | null;
  space?: Space;
} & TeamSettings;

export interface Page {
  page: PageData;
}

export type SupporterInvitation = {
  id: string;
  email: string;
  participant: Participant;
};

export interface SupporterInvitationData {
  supporterInvitations: SupporterInvitation[] | [];
}

export interface PageDataDraftBlock extends Omit<PageData, 'blocks'> {
  blocks: BlockState[];
}
export interface PageView {
  page: PageDataDraftBlock;
}
export interface SavePage {
  savePage: PageDataDraftBlock;
}

export interface PageCard {
  id: string;
  title: string;
  createdAt: string;
  status: 'active' | 'archived';
  updatedAt: string;
  isTemplate: boolean;
  responsesCount: number;
  totalCollectedCents: number;
  organizationId: string;
  slug: string;
  space?: Partial<Space>;
}

export interface PageCardData {
  data: PageCard;
}

export interface CopyPageData {
  copyPage: PageCard;
}

export interface PagesQuery {
  archivedCount: number;
  activeCount: number;
  totalCollectedCents: number;
  responsesCount: number;
  pages?: PageCard[];
}

export interface PagesData {
  pagesQuery: PagesQuery | null;
}

export interface OrganizationPagesData {
  organizationPagesQuery: PageCard[];
}

export type PriceSettings = {
  processingFeePayer?: ProcessingFeePayer;
  tipsEnabled?: boolean;
};

export type TeamSettings = {
  p2pEnabled?: boolean;
  p2pOrganizationName?: string;
  p2pProgramName?: string;
  p2pReferToMeAs?: string;
  defaultParticipantGoalCents?: number;
  collectBeyondParticipantGoal?: boolean;
  defaultParticipantMessage?: string;
};

export type ImageType = {
  name: string;
  label: string;
  ref: RefObject<HTMLInputElement>;
  file?: string;
  imageIsEmpty?: boolean;
  uploadIcon?: string;
};
